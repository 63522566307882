<template>
  <!-- 之前的首页显示 -->
  <el-container>
    <!-- 背景图 -->
    <el-image :src="require('@/assets/Login/bg2.jpg')" class="login-banimg"></el-image>
    <!-- 顶部 -->
    <el-header>
      <Header />
    </el-header>
    <!-- 中间内容 -->
    <el-main>
      <div class="BigDiv">
        <div class="KuaiDiv" style="background: linear-gradient(to right top, #663bdd, #a59fed)" @click="orinman">
          <div class="iconfont icon-jixiaoguanli"></div>
          <span>机构信息管理</span>
        </div>
        <div class="KuaiDiv" style="background: linear-gradient(90deg, #f4c2ff, #e58af9)" @click="basindic">
          <div class="iconfont icon-zhibiaokuchaxun"></div>
          <span>绩效指标库管理</span>
        </div>

        <div class="KuaiDiv" style="background: linear-gradient(to right top, rgb(88, 152, 244), rgb(159, 228, 237))" @click="depperindicman">
          <div class="iconfont icon-yonghuguanli"></div>
          <span>科室绩效分配方案</span>
        </div>

        <div class="KuaiDiv" style="background: linear-gradient(to right top, rgb(184, 163, 170), rgb(197, 190, 192))" @click="depperbusdafi">
          <div class="iconfont icon-yonghuguanli"></div>
          <span>科室绩效数据填报</span>
        </div>

        <div class="KuaiDiv" style="background: linear-gradient(to right top, rgb(128, 197, 68), rgb(167, 230, 76))" @click="copeplan">
          <div class="iconfont icon-yonghuguanli1-copy"></div>
          <span>绩效薪酬核算管理</span>
        </div>

        <div class="KuaiDiv" style="background: linear-gradient(90deg, #4c35c7, #64a2fa)" @click="driven">
          <div class="iconfont icon-jiegou1"></div>
          <span>驱动型绩效智慧大数据</span>
        </div>
      </div>

    </el-main>
  </el-container>
</template>

<script>
import Header from "@/components/Header";
export default {
  data() {
    return {
    };
  },
  created() {
    // console.log(this.$store.state);
  },
  components: {
    Header,
  },
  //监听函数
  watch: {
    $route() {
      // console.log(this.$store.state);
    },
  },
  methods: {

    basindic() {
      this.$router.push({ path: "/basindic" });
    },
    // 机构信息管理
    orinman() {
      this.$router.push({ path: "/oridepman" });
    },
    depperindicman() {
      this.$router.push({ path: "/depperindicman" });
    },
    depperbusdafi() {
      this.$router.push({ path: "/depperbusdafi" });
    },
    copeplan(){
      this.$router.push({ path: "/copeplan" });
    },
    // 点击驱动型绩效智慧大数据
    driven() {
      this.$router.push({ path: "/driven" });
    },
    // 开发中
    underDev() {
      this.$message({
        message: "正在开发中~",
        type: "warning",
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-header {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  background: rgb(2, 24, 36);
  color: white;
  justify-content: space-between;
}

.login-banimg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.el-main {
  position: relative;
  top: 50px;
  .BigDivDep {
    width: 70%;
  }
  .BigDiv {
    width: 88%;
  }
  .BigDiv,
  .BigDivDep {
    display: flex;

    margin: 0 auto;
    margin-top: 10px;
    .KuaiDepDiv {
      width: 250px;
      height: 250px;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .iconfont {
        font-size: 40px;
        color: white;
      }
      span {
        color: white;
        font-size: 21px;
        margin-top: 10px;
      }
    }
    .KuaiDiv {
      flex: 1;
      height: 250px;
      margin-right: 10px;
      margin-bottom: 20px;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .iconfont {
        font-size: 40px;
        color: white;
      }
      span {
        color: white;
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }
}
</style>
